
import { defineAsyncComponent, ref, defineComponent, PropType } from 'vue';
import { GetSignedPolicyInfoByPrivacyPolicy } from '@/api/common/privacyPolicy';

export default defineComponent({
    props: {
        formData: {
            type: Object as PropType<GetSignedPolicyInfoByPrivacyPolicy>,
            default: () => ({})
        }
    }
});

